// gatsby-config.js
exports.onInitialClientRender = () => {
  require('typeface-rubik')
  // window.metrical = {
  //   app: 'tdYlgPxUI',
  // }
  // const script = document.createElement('script')
  // script.src = 'https://api.metrical.xyz/script.js'
  // document.head.appendChild(script)
}
